.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 390px;

  span {
    margin-bottom: 60px;
    span {
      border: none;
      background: radial-gradient(#7912ff 30px, #eb14e4);
    }
  }
}

.time-tracking {
  .forms-wrapper {
    position: relative;
  }

  textarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    width: 390px;
    height: 55px;

    &::placeholder {
      opacity: 0.7;
      font-size: 16px;
    }
  }

  .timers-wrapper {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;

    .play-img {
      display: flex;
      position: relative;
      top: 38px;
    }
  }

  .add-task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    button {
      border: none;
      background: none;
      cursor: pointer;
      font-size: 16px;
    }

    .logout {
      color: #008bff;
    }

    p {
      font-size: 20px;
      text-align: center;
      font-weight: 700;
    }

    .button {
      width: 70px;
    }
  }
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 0;
  width: 390px;
  height: 55px;

  label {
    font-size: 18px;
    margin: 8px 0 4px;
  }

  & > div {
    width: 100%;

    & > div {
      border: 1px solid #d7d7d7;
      border-radius: 6px;
      padding: 8px;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  select {
    cursor: pointer;
  }

  select,
  textarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d7d7d7;
    border-radius: 6px;
    width: 100%;
    height: inherit;
    padding: 16px;
    font-size: 16px;
    resize: none;

    &:disabled {
      background-color: #f2f2f2;
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

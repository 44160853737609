.blur-background {
  position: absolute;
  z-index: 1;
  top: -10px;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.3);
}

.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  width: 100%;
  height: 60%;
  margin-bottom: 60px;

  &.logged-in {
    margin-bottom: 5px;
  }

  img.womanLaptop {
    margin-left: 115px;
    height: 185px;
  }

  img.left {
    align-self: flex-start;
    position: absolute;
    top: 8px;
    left: 16px;
  }

  .threeDots {
    position: absolute;
    right: 16px;
    top: 5px;
    width: 5px;
    opacity: 0.45;
    cursor: pointer;
  }

  // .logotext {
  //   width: 196px;
  //   height: 29px;
  // }

  .greeting {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

.logout {
  color: rgba(0, 139, 255, 1);
  font-size: 16px;
  letter-spacing: 0%;
  text-align: left;
  border: none;
  background-color: transparent;
}

.add-task-manualy {
  color: black;

  form {
    width: 100%;

    .inputs-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .task-tracked {
        font-size: 20px;
      }

      .time {
        font-size: 22px;
        display: flex;

        &:first-child {
          margin-right: 16px;
        }
      }

      .date {
        position: absolute;
        color: white;
        right: 25px;
        top: 20px;
      }

      .input-control {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        justify-content: space-between;

        label {
          color: white;
          font-size: 18px;
        }

        input:focus {
          outline: none;
        }

        input {
          width: 61px;
          height: 48px;
          border: 1px solid #d7d7d7;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 10px;
          font-size: 16px;

          &::placeholder {
            opacity: 0.7;
            font-size: 16px;
          }
        }

        input[type='date'] {
          width: 158px;

          &:hover {
            // cursor: pointer;
          }
        }

        &.confirm-task {
          flex-direction: column;
          label {
            color: black;
            opacity: 0.8;
            font-size: 14px;
            margin: 0 0 4px 0;
          }
          input {
            height: 32px;
          }
        }
      }

      .button {
        background-color: black;
        color: white;
        height: 48px;
        width: 79px;
        margin-bottom: 6px;
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.menu {
  position: absolute;
  right: 0;
  top: 46px;
  width: 70%;
  min-height: 80px;
  z-index: 1;
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 16px;
  background-color: white;

  .version {
    position: absolute;
    right: 17px;
    top: 38px;
    font-size: 14px;
    color: gray;
  }

  h2 {
    text-align: center;
    margin: 0;
    font-size: 24px;
    color: #a513f4;
  }

  .menu-item {
  }

  p {
    font-size: 16px;

    &:hover,
    &.bold {
      cursor: pointer;
      font-weight: 600;
    }
  }

  form {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .inputs-wrapper {
      .input-control {
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        // justify-content: space-between;

        .calendar-wrapper {
          .rdrDefinedRangesWrapper {
            display: none;
          }

          position: relative;
          transform: scale(0.8);
          left: -30px;
          top: -15px;
        }

        label {
          font-size: 16px;

          &#reserveDuty-label {
            white-space: nowrap;
          }
        }

        input:focus {
          outline: none;
        }

        input {
          // width: 61px;
          // height: 48px;
          border: 1px solid #d7d7d7;
          box-sizing: border-box;
          border-radius: 6px;
          padding: 10px;
          font-size: 14px;

          &::placeholder {
            opacity: 0.7;
            font-size: 14px;
          }
        }

        input[type='date'] {
          width: 158px;

          &:hover {
            // cursor: pointer;
          }
        }
      }
    }

    .button {
      position: absolute;
      right: 20px;
      bottom: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: black;
      color: white;
      height: 40px;
      width: 79px;
      margin-bottom: 9px;
    }
  }
}

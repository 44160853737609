.report {
  width: 100%;
  height: 360px;
  margin-top: 12px;

  .report-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 16px;

    .input-control {
      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;
      justify-content: space-between;

      .react-datepicker__day--highlighted {
        background-color: red;
      }

      .redDot {
        background: red;
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        left: 120px;
        top: 19px;
      }

      input:focus {
        outline: none;
      }

      input {
        width: 158px;
        height: 48px;
        border: 1px solid #d7d7d7;
        border-radius: 6px;
        padding: 10px;
        font-size: 16px;

        &::placeholder {
          opacity: 0.7;
          font-size: 16px;
        }
      }
    }

    .report-table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      font-size: 14px;

      .table-header,
      .table-row {
        display: grid;
        grid-template-columns: 70% 30%;
        padding: 6px;

        &.edit-mode {
          grid-template-columns: 50% 50%;
        }
      }
      .table-header {
        font-weight: 700;
      }

      .table-body {
        overflow-y: auto;
        height: 265px;

        .table-row {
          position: relative;
          margin: 16px 0;
          //   &:hover {
          //     cursor: pointer;
          //     background-color: lighten(#7912ff, 35%);
          //     border-radius: 6px;
          //   }

          &:first-child::before {
            content: '';
            position: absolute;
            top: -8px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: black;
            opacity: 0.2;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: black;
            opacity: 0.2;
          }

          div {
            font-size: 14px;
          }

          .time {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            grid-row: span 2;
            font-size: 16px;
          }

          .company {
            font-weight: 700;
            font-size: 12px;
            opacity: 0.5;
            margin: 0 0 8px 0;
          }

          .down-arrow {
            position: absolute;
            right: 8px;
            top: 7px;
            width: 15px;
            height: 15px;
            transition: transform 0.2s ease;

            &.up-arrow {
              transform: rotateX(180deg);
            }
          }

          .pencil {
            position: absolute;
            right: 8px;
            top: 16px;
            cursor: pointer;
          }

          .add-task-manualy {
            height: 30px;
            width: 85%;
            // margin-right: 160px;

            .input-control.confirm-task {
              margin-right: 4px;
              label {
                font-size: 12px;
              }
              input {
                width: 50px;
              }
            }

            .button {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 30px;
              width: 50px;
              margin-bottom: -10px;
              font-size: 16px;
            }
          }
        }
      }

      .total {
        position: relative;
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        font-size: 18px;
        font-weight: 700;
        margin-top: 8px;

        &::before {
          content: '';
          position: absolute;
          top: -8px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: black;
        }
      }
    }
  }
}

.tabs-bar {
  display: flex;
  justify-content: flex-start;
  margin-top: 60px;
  padding-left: 16px;
  width: 100%;

  div {
    position: relative;
    font-size: 17px;
    margin-right: 16px;
    cursor: pointer;

    &::after {
      content: '';
      background: linear-gradient(to right, #7912ff -30px, #eb14e4);
      width: 0px;
      height: 3px;
      position: absolute;
      bottom: -5px;
      left: 0;
      transition: width 0.3s ease-out;
    }

    &:hover::after,
    &.active::after {
      width: 100%;
    }
  }
}

.button {
  position: relative;
  right: 0;
  font-size: 18px;
  width: 80px;
  height: 40px;
  background-color: $color4;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);

  &:hover {
    background-color: darken($color4, 7%);
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      background-color: $color4;
    }
  }

  &:disabled {
    box-shadow: none;
    opacity: 0.4;
  }
}

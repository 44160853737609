* {
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

body {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 508px;
  width: 420px;
  padding: 16px 0;
  border-radius: 7px;
  box-shadow: 0px 6px 11px 0px rgba(0, 0, 0, 0.25);
  border-color: rgba(241, 241, 241, 1);
  border-width: 1px;
  border-style: solid;
  background-color: rgba(254, 254, 254, 1);
}

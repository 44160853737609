.timer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 16px;
  width: 100%;
  height: 55px;
  background-color: black;
  border-radius: 6px;

  &.redBg {
    background-color: #ff0000;
  }

  .submiting {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-left: 6px;
    }
  }

  .buttons {
    button {
      display: flex;
      align-items: center;
      background: none;
      border: none;
      color: white;
      cursor: pointer;
      font-size: 16px;

      &:disabled {
        color: #ccc;
        cursor: not-allowed;
      }

      img {
        margin-right: 12px;
      }
      img.stop {
        margin-right: 5px;
      }
    }
  }

  // .title {
  //   margin: 4px 0 6px;
  //   font-size: 18px;
  // }

  .digits {
    font-size: 16px;
    // margin-bottom: 8px;
  }
}
